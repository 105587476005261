/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserVerificationDto } from '../models/user-verification-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedUserVerificationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createManualVerification
   */
  static readonly CreateManualVerificationPath = '/api/user-verifications';

  /**
   * Creates a manual user verification in manual review state. Quite hacky...
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createManualVerification()` instead.
   *
   * This method doesn't expect any request body.
   */
  createManualVerification$Response(params?: {
    userId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserVerificationDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserVerificationService.CreateManualVerificationPath, 'post');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserVerificationDto>;
      })
    );
  }

  /**
   * Creates a manual user verification in manual review state. Quite hacky...
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createManualVerification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createManualVerification(params?: {
    userId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<UserVerificationDto> {

    return this.createManualVerification$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserVerificationDto>) => r.body as UserVerificationDto)
    );
  }

  /**
   * Path part for operation deleteUserVerification
   */
  static readonly DeleteUserVerificationPath = '/api/user-verifications/{id}';

  /**
   * Deletes an user verification including its uploaded documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserVerification()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserVerification$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserVerificationService.DeleteUserVerificationPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes an user verification including its uploaded documents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserVerification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserVerification(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteUserVerification$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
